import request, { downloadFile } from "@/utils/axios";

// export function getBrand() {
//   return request.get("/campaign/brand");
// }

// 投放量级
export function getQuantity(params) {
    return request.get("/campaign/dashboard/trace/quantity", params);
}

// 投放效果
export function getEffect(params) {
    return request.get("/campaign/dashboard/trace/effect", params);
}

// 投放分布
export function getDistribution(params) {
    return request.get("/dashboard/trace/distribution", params);
}

// 投放节奏
export function getTrend(params) {
    return request.get("/dashboard/trace/trend", params);
}

// /dashboard/overview
export function getDashboardOverview(params) {
    return request.get("/dashboard/trace/overview", params);
}

///dashboard/kol
export function getDashboardKol(params) {
    return request.get("/dashboard/trace/kol", params);
}

// /dashboard/kol/report
export function downDashBoardKol(params, fileName) {
    return downloadFile("/dashboard/trace/kol/report", params, fileName);
}

// dashboard/agency
export function getDashboardAgenct(params) {
    return request.get("dashboard/trace/agency", params);
}

// dashboard/brand
export function getDashboardBrandInfo(params) {
    return request.get("/dashboard/trace/brand", params);
}

// 根据品牌获取品线 /dashboard/brand
// dashboard/brandLine
export function getDashboardBrandLineByBrand(params) {
    return request.get("/dashboard/trace/brandLine", params);
}

// 根据品牌+品线获取产品 /dashboard/brand
// dashboard/brandLine
export function getDashboardProduct(params) {
    return request.get("/dashboard/trace/product", params);
}
